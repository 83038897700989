





















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ISeqSend, ISeqSendsSearchParams } from '../../../interfaces/seq_sends';
import { dispatchGetSeqSends, dispatchDeleteSeqSend } from '../../../store/main/accessors';
import { readHasAdminAccess } from '@/store/main/accessors';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class SeqSends extends Vue {
  public seqSendId: number | null = null;
  public loading: boolean = false;
  public seqSends: ISeqSend[] = [];
  public total: number = 0;

  public pagination: { sortBy; descending; page; rowsPerPage; footerProps } = {
    sortBy: ['id'],
    descending: [false],
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };

  public $refs!: {
    confirm: InstanceType<typeof ConfirmDialog>;
  };

  public async updateSeqSends(clearPagination?: boolean) {
    if (clearPagination) {
      this.pagination.page = 1;
    }
    this.loading = true;
    const data = await this.getDataFromApi();
    this.total = data.total;
    this.seqSends = data.items;
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public async onPaginationChange(val) {
    await this.updateSeqSends();
  }

  public get headers() {
    const headers = [
      {
        text: this.$vuetify.lang.t('$vuetify.name'),
        value: 'name',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.actions'),
        value: 'id',
        align: 'right',
      },
    ];

    return headers;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async mounted() {
    await this.updateSeqSends();
  }

  public async getDataFromApi() {
    const { sortBy, descending, page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    const params: ISeqSendsSearchParams = {
      skip,
      limit: rowsPerPage,
      orderBy: sortBy[0],
      descending: descending[0],
      seqSendId: this.seqSendId,
    };
    return await dispatchGetSeqSends(this.$store, params);
  }

  public async deleteSeqSend(id: number) {
    if (await this.$refs.confirm.open(this.$vuetify.lang.t('$vuetify.deleteEntryConfirmation'))) {
      await dispatchDeleteSeqSend(this.$store, id);
      await this.updateSeqSends();
    }
  }
}
